.grid {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto auto auto auto auto auto auto auto auto;
  grid-template-rows: auto auto auto auto auto auto auto auto auto;
}

.image {
  max-height: 400px;
}

.text-data-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 60%;
  max-width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  line-height: 28px;
}

.div-block-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 50px;
  max-width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #bebebe;
}

.text-block {
  width: 30%;
  max-width: none;
  min-width: 150px;
  font-weight: 700;
}

.text-block-2 {
  width: 100%;
  max-width: 100%;
}

.div-block-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 100%;
  padding: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border-bottom: 1px solid #bebebe;
}

.text-block-3 {
  width: 100%;
  max-width: none;
  min-width: 150px;
  font-weight: 700;
}

.text-block-4 {
  width: 100%;
  max-width: 100%;
}

.image-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 30%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.image-wrapper > * {
  min-height: 0%;
}

.div-block-5 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.image-2 {
  width: 33%;
  -o-object-fit: contain;
  object-fit: contain;
}

.image-3 {
  width: 33%;
  -o-object-fit: contain;
  object-fit: contain;
}

.image-4 {
  width: 33.3%;
  -o-object-fit: contain;
  object-fit: contain;
}

.image-box {
  width: 50%;
  padding: 10px;
  -o-object-fit: contain;
  object-fit: contain;
}

.body {
  border-bottom: 1px solid #bebebe;
}

.detail-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
