.paging-container{
  display:flex;
  align-content: flex-end;
  padding:10px 0;
  a,p{
    margin:0 5px;
  }
  p{
    line-height:16px;
    font-size: 16px;
    padding-top:6px;
  }
}
