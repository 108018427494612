.comment {
  padding: 10px;
  background: #f3f3f3;
  border-radius: 5px;
  margin: 15px 5px;

  p {
    font-size: 16px;
    line-height: 24px;
  }

  p.author {
    font-size: 14px;
    text-align: right;
  }
}
